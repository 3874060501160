import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Footer, FormGrid, FormConfig, Header, Button, TextField } from 'components';
import { useForm, useService } from 'hooks';
import validate from 'helpers/validate';
import { getLocaleValidations, getLocaleFields, getLocaleDataForm } from 'helpers/locale';

const validations = {
  name: [validate.isEmpty()],
  key: [validate.isEmpty()],
  ...getLocaleValidations(),
};

const Form: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ pupStatus?: string }>();
  const [form, onChange] = useForm({ validations });

  const LIST_CLASSES = `/params/reproduction/pup-status`;

  const createService = useService('post', `/couple-reproduction-pup-status`, {}, false);
  const updateService = useService('patch', `/couple-reproduction-pup-status/${params.pupStatus}`, {}, false);
  const pupStatusService = useService(
    'get',
    `/couple-reproduction-pup-status/${params.pupStatus}`,
    {},
    !!params.pupStatus
  );

  const handleOnSave = async () => {
    const data = {
      name: form.getValue('name'),
      key: form.getValue('key'),
      ...getLocaleDataForm(form),
    };

    const service = params.pupStatus ? updateService : createService;
    const response = await service.fetch(data);

    if (response?.data?.status === 'OK') {
      toast('Salvo com sucesso');
      return navigate(LIST_CLASSES);
    }

    if (response.data.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao salvar', { type: 'error' });
  };

  const handleFillFields = useCallback(() => {
    const data: DBird.IBirdColor = pupStatusService?.data?.data?.coupleReproductionPupStatus;

    if (!data) return;

    form.setValues({
      ...data,
    });
  }, [pupStatusService?.data]);

  useEffect(() => {
    handleFillFields();
  }, [handleFillFields]);

  const formConfig: FormConfig = [
    [
      {
        schema: 'name',
        size: { md: 6 },
        type: TextField,
        props: (schema) => ({
          label: 'Nome',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
      {
        schema: 'key',
        size: { md: 6 },
        type: TextField,
        props: (schema) => ({
          label: 'Identificador',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
    ],
    [...getLocaleFields(form, onChange)],
  ];

  return (
    <>
      <Header
        title={params.pupStatus ? 'Editar status do filhote' : 'Novo status do filhote'}
        left={
          <Button variant="outlined" to={LIST_CLASSES}>
            Voltar
          </Button>
        }
      />

      <FormGrid config={formConfig} />

      <Footer>
        <Button onClick={form.trySave(handleOnSave)} loading={createService.loading || updateService.loading}>
          Salvar
        </Button>
      </Footer>
    </>
  );
};

export default Form;
