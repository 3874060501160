import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Footer, FormGrid, FormConfig, Header, Button, TextField, SelectField } from 'components';
import { useForm, useService } from 'hooks';
import validate from 'helpers/validate';
import { getLocaleValidations, getLocaleFields, getLocaleDataForm } from 'helpers/locale';

const validations = {
  name: [validate.isEmpty()],
  key: [validate.isEmpty()],
  specieGroup: [validate.isEmptySelect()],
  temperature: [validate.isEmpty()],
  humidity: [validate.isEmpty()],
  angle: [validate.isEmpty()],
  interval: [validate.isEmpty()],
  ...getLocaleValidations(),
};

const Form: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ incubator?: string }>();
  const [form, onChange] = useForm({ validations });

  const LIST_CLASSES = `/params/general/incubator-specie-group`;

  const createService = useService('post', `/incubator-specie-group`, {}, false);
  const updateService = useService('patch', `/incubator-specie-group/${params.incubator}`, {}, false);
  const incubatorService = useService('get', `/incubator-specie-group/${params.incubator}`, {}, !!params.incubator);
  const specieGroup = useService('get', `/specie-group`, { limit: 1000 }, true);

  const handleOnSave = async () => {
    const data = {
      name: form.getValue('name'),
      key: form.getValue('key'),
      specieGroup: form.getValue('specieGroup')?.value,
      temperature: form.getValue('temperature'),
      humidity: form.getValue('humidity'),
      angle: form.getValue('angle'),
      interval: form.getValue('interval'),
      ...getLocaleDataForm(form),
    };

    const service = params.incubator ? updateService : createService;
    const response = await service.fetch(data);

    if (response?.data?.status === 'OK') {
      toast('Salvo com sucesso');
      return navigate(LIST_CLASSES);
    }

    if (response.data.status === 'ERROR') {
      form.setErrors(response.data?.errors || {});
      return toast('Verifique os erros do formulários', { type: 'error' });
    }

    return toast('Houve um erro ao salvar', { type: 'error' });
  };

  const fetchData = () => {
    const data = incubatorService?.data?.data?.incubatorSpecieGroup;

    if (!data) return;

    form.setValues({
      ...data,
    });
  };

  useEffect(() => {
    fetchData();
  }, [incubatorService.data]);

  const formConfig: FormConfig = [
    [
      {
        schema: 'name',
        size: { md: 6 },
        type: TextField,
        props: (schema) => ({
          label: 'Nome',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
      {
        schema: 'key',
        size: { md: 6 },
        type: TextField,
        props: (schema) => ({
          label: 'Identificador',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
      {
        schema: 'specieGroup',
        size: { md: 12 },
        type: SelectField,
        props: (schema) => ({
          label: 'Espécies',
          options: specieGroup?.data?.data?.specieGroup?.items,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
          format: {
            value: '_id',
            label: 'name',
          },
        }),
      },
      {
        schema: 'temperature',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Temperatura',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
      {
        schema: 'humidity',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Umidade',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
      {
        schema: 'angle',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Ângulo',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
      {
        schema: 'interval',
        size: { md: 12 },
        type: TextField,
        props: (schema) => ({
          label: 'Intervalo',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: onChange(schema),
        }),
      },
    ],
    [...getLocaleFields(form, onChange)],
  ];

  return (
    <>
      <Header
        title={params.incubator ? 'Editar pré-set incubadora' : 'Novo pré-set incubadora'}
        left={
          <Button variant="outlined" to={LIST_CLASSES}>
            Voltar
          </Button>
        }
      />

      <FormGrid config={formConfig} />

      <Footer>
        <Button onClick={form.trySave(handleOnSave)} loading={createService.loading || updateService.loading}>
          Salvar
        </Button>
      </Footer>
    </>
  );
};

export default Form;
