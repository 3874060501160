import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useService } from 'hooks';
import { TableList, TableListButtons, TableListButtonsProps, Header, Button } from 'components';

const PAGE_KEY = 'BIRD_PAGE';

const List: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = searchParams.get(PAGE_KEY) || 1;

  const listService = useService('get', `/bird-color`, { page: currentPage }, true, [currentPage]);
  const removeService = useService('patch', (data) => `/bird-color/${data?.id}`, {}, false);

  const handleOnActive = async (row: any) => {
    const response = await removeService.fetch({ isActive: !row.isActive }, { id: row._id });

    if (response?.data?.status === 'OK') {
      toast('Padrão de cor inativo');
      return listService.fetch();
    }

    return toast('Houve um erro ao inativar', { type: 'error' });
  };

  const handleOnChangePage = (page) => {
    setSearchParams({ [PAGE_KEY]: page });
  };

  const handleRowOnClick = (row: DBird.IBirdColor) => {
    navigate(`${location.pathname}/edit/${row._id}`);
  };

  const tableListButtonsConfig: TableListButtonsProps['config'] = [
    {
      icon: (row) => (row.isActive ? 'eye-slash' : 'eye'),
      label: 'Mostrar no app',
      onClick: handleOnActive,
    },
  ];

  const tableListConfig = [
    {
      path: 'name',
      title: 'Nome',
      size: {
        md: 6,
      },
    },
    {
      path: 'birdSpecie.name',
      title: 'Espécie',
      size: {
        md: 4,
      },
    },
    {
      path: (row) => <TableListButtons data={row} config={tableListButtonsConfig} />,
      title: '',
      size: {
        md: 2,
      },
    },
  ];

  return (
    <>
      <Header title="Padrão de cor" right={<Button to={`${location.pathname}/create`}>Novo</Button>} />

      <TableList
        config={tableListConfig}
        data={listService?.data?.data}
        dataPath="birdColor.items"
        paginate
        paginatePath="birdColor"
        onRowClick={handleRowOnClick}
        onChangePage={handleOnChangePage}
      />
    </>
  );
};

export default List;
